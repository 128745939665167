import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import type { Portfolio } from "./types";
import "./style.scss";

type Props = {
  data: Portfolio[];
};

const responsive = {
  4: {
    breakpoint: { max: 10000, min: 2000 },
    items: 4,
  },
  3: {
    breakpoint: { max: 2000, min: 1200 },
    items: 3,
  },
  2: {
    breakpoint: { max: 1200, min: 768 },
    items: 2,
  },
  1: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

const PortfolioCarousel = ({ data }: Props) => {
  return (
    <Carousel
      responsive={responsive}
      infinite
      autoPlay
      autoPlaySpeed={5000}
      transitionDuration={500}
      showDots
      swipeable
      draggable
      dotListClass="dots"
      renderDotsOutside
    >
      {data
        .sort((a, b) => a.order - b.order)
        .map((item) => (
          <div className="item" key={item.order}>
            <div className="logo">
              <img src={`/lp_portfolio/${item.image}`} alt="portfolio-logo" />
            </div>
          </div>
        ))}
    </Carousel>
  );
};

export default PortfolioCarousel;
